import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AtendimentoDecService {


  private apiUrl = environment.API_URL

  constructor(private http: HttpClient) { }

  pesquisarContribuinte(codigo) {
    return this.http.get(
      `${this.apiUrl}/cadastros/cadastro/cpfCnpj/${codigo}`
    );
  }

  enviarSolicitacao(obj) {
    return this.http.put(
      `${this.apiUrl}/cadastros/cadastroEmail/enviarEmailFaleBR`, obj
    )
  }
}

import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from "@angular/core";
import { Router } from '@angular/router';

import { nfaService } from './../../services/nfa.service';
import { ContribuinteService } from 'src/app/services/contribuinte.service';
import { ModoEscuroService } from 'src/app/services/modoEscuro.service';
import { TermoAdesaoService } from "src/app/services/termo-adesao.service";
import { UsuarioLogadoService } from "src/app/services/usuarioLogado.service";
import Swal from "sweetalert2";
import { MenuService } from 'src/app/services/menu.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CadastroService } from 'src/app/services/cadastro.service';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss'],
  animations: [
    trigger('abrirTermo', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('200ms', style({ opacity: 0 }))
      ])
    ]),
  ],
})

export class InicioComponent implements OnInit{

  dadosUsuario : any;
  codigoContribuinte: any;
  objUsuario: any;
  dadosIntegracao: any;
  contribuinteEmUso: string;
  cadastroEmUso: boolean = false;
  primeiroAcesso: string;
  usuarioLogado: any;
  nivelUsuario: [];
  nivelContribuinte: string;
  objTermoAdesao: any;
  termoAdesao: any;
  registroTermoAdesao: boolean = false;
  atBottom: boolean = false;
  backdrop: boolean = false;
  loading = false;
  modalAtivo: boolean = false;
  abrirModalNfa: boolean = false;
  abrirModalEmitirNfa: boolean = false;
  abrirModalContatos: boolean = false;
  formContatos: FormGroup;
  modoEscuro: boolean = false;
  menusAutorizados: any[] = []
  menus = [
    { dscMenu: 'Caixa Postal Eletrônica'},
    { dscMenu: 'Procuração Eletrônica'},
    { dscMenu: 'REFIS'},
    { dscMenu: 'Portal de Negociação de Débitos' }, 
    { dscMenu: 'NFA-e'},
    { dscMenu: 'Portal de Acompanhamento de Parcelamento / Acordos' },
    { dscMenu: 'Cadastro de Contribuintes do I.C.M.S' },
    { dscMenu: 'IPVA' },
    { dscMenu: 'Perfil do Contribuinte' }
  ];

  constructor(
    private router: Router,
    private termoAdesaoService: TermoAdesaoService,
    private usuarioLogadoService: UsuarioLogadoService,
    private nfaService: nfaService,
    private contribuinteService: ContribuinteService,
    private modoEscuroService: ModoEscuroService,
    private menuService: MenuService,
    private formBuilder: FormBuilder,
    private cadastroService: CadastroService
  ) {}
 
  ngOnInit(): void {
    setTimeout(() => {
      this.usuarioLogado = this.usuarioLogadoService.getUsuarioLogado();
      this.dadosIntegracao = this.usuarioLogadoService.getDadosIntegracao();
      this.cadastroEmUso = this.usuarioLogado.cadastroEmUso;
      this.primeiroAcesso = localStorage.getItem('primeiroAcesso');
      if (this.dadosIntegracao.mensagem && this.primeiroAcesso != 'false' && this.dadosIntegracao.sessao.contribuinte) {
        Swal.fire({
          icon: 'info',
          html: this.dadosIntegracao.mensagem,
          allowOutsideClick: false
        });
      }
      localStorage.setItem('primeiroAcesso', 'false');
      this.verificarTermoAdesao();
      this.nivelUsuario = this.dadosIntegracao.sessao.cadastro.niveisUsuario;
      if (this.nivelUsuario[this.nivelUsuario.length - 1]['id'] == 1) {
        localStorage.setItem('nivelUsuario', 'bronze');
        this.validarNivelUsuario();
      } else if (this.nivelUsuario[this.nivelUsuario.length - 1]['id'] == 2) {
        localStorage.setItem('nivelUsuario', 'prata');
     } else if (this.nivelUsuario[this.nivelUsuario.length - 1]['id'] == 3) {
       localStorage.setItem('nivelUsuario', 'ouro');
       }
      this.nivelContribuinte = localStorage.getItem('nivelUsuario');
    }, 500);
    this.getMenusAutorizados();

    this.modoEscuroService.modoEscuro$.subscribe(modo => {
      this.modoEscuro = modo;
    });
  }

  getMenusAutorizados() {
    setTimeout(() => {
      const stringPerfilSelecionado = localStorage.getItem('perfilSelecionado');
      let perfilAcesso;
      if(stringPerfilSelecionado !== 'undefined') { 
        perfilAcesso = JSON.parse(stringPerfilSelecionado);
      }
      this.menuService.getMenusAutorizados(perfilAcesso?.codigo).subscribe((res: any[]) => {
        const menusPerfil = res;
        this.menusAutorizados = this.menus.filter(menu => {
          return menusPerfil.some(menuPerfil => menuPerfil.dscMenu.toUpperCase() == menu.dscMenu.toUpperCase());
        });
      });
    }, 500);
  }

  exibirMenu(menu): boolean {
    let exibeMenu: boolean = true;
    if(this.menusAutorizados !== undefined && this.menusAutorizados.length !== 0 ) {
      exibeMenu = this.menusAutorizados.some(menuPerfil => menuPerfil.dscMenu.toUpperCase().includes(menu.toUpperCase()))
    }
    
    return exibeMenu
  }

  validarNivelUsuario() {
    Swal.fire({
      icon: 'warning',
      title: 'Alerta!',
      html: `<p>Identificamos que seu nível de conta é Bronze.
        Neste nível alguns serviços ficam indisponíveis.
        Para saber mais sobre os níveis da conta gov.br acesse
        <a href="https://www.gov.br/governodigital/pt-br/conta-gov-br/saiba-mais-sobre-os-niveis-da-conta-govbr">
        Saiba mais sobre os níveis da conta gov.br — Governo Digital (www.gov.br)</a>
        </p>`
    })
  }

  abrirModal(){
    if (this.modalAtivo == true) {
      this.modalAtivo = false;
    } else {
      this.modalAtivo = true;
    }
  }

  abrirPerfilContribuinte(){
    this.router.navigate(['perfil-contribuinte']);
  }

  abrirCaixaPostal() {
    if (this.nivelContribuinte !=='bronze'){
      this.router.navigate(['/caixa-postal']);
    }
  }

  abrirNegociacaoDebito(){
    const codigoContribuinte = 'credor=60&tp=7&d=' + this.usuarioLogado.cpfCnpj;
    const base64 = btoa(codigoContribuinte);
    const externalUrl = 'https://portaltocantins.giexonline.com.br/Start.aspx?dec=' + base64;
    window.open(externalUrl, '_blank');
    this.modalAtivo = false;
  }

  abrirPortalAcompanhamento() {
    const codigoContribuinte = 'credor=60&tp=3&d=' + this.usuarioLogado.cpfCnpj;
    const base64 = btoa(codigoContribuinte);
    const externalUrl = ' https://portaltocantins.giexonline.com.br/Start.aspx?dec=' + base64;
    window.open(externalUrl, '_blank');   
  }

  verificarTermoAdesao() {
    let codigo = this.usuarioLogado.codigoCadastro;
    let contribuinteEmUso = localStorage.getItem('contribuinteEmUso');
    this.loading = true;
    this.termoAdesaoService.necessidadeAdesao().subscribe(
      (res) => {
        if (res) {
          this.termoAdesaoService.verificarCadastro(codigo).subscribe((res) => {
            if (res) {
              this.backdrop = false;
              if (!contribuinteEmUso) {
                Swal.fire({
                  icon: 'info',
                  text: `Seu acesso foi realizado com sucesso, no entanto, 
                  como não foi encontrado sua classificação como contribuinte 
                  ou que tenha com relação com contribuinte do Estado de Tocantins, 
                  alguns serviços ficarão inacessíveis até que aceite 
                  o termo de adesão que será apresentado à seguir.`,
                  allowOutsideClick: false
                });
              }
              this.verificarAtualizacaoContato();
            } else {
              if (!contribuinteEmUso) {
                Swal.fire({
                  icon: 'info',
                  text: this.dadosIntegracao.mensagem,
                  allowOutsideClick: false
                });
              }
              this.termoAdesaoService.getTermoAdesao().subscribe((res) => {
                this.objTermoAdesao = res;
                this.termoAdesao = this.objTermoAdesao[0].termoAdesao;
                if (this.objTermoAdesao[0].existeTermo) {
                  this.registroTermoAdesao = true;
                  this.backdrop = true;
                } else {
                  this.backdrop = true;
                  this.registroTermoAdesao = false;
                }
                this.loading = false;
              });
            }
          });
        }
      },
      (error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Houve um erro ao tentar obter o Termo de Adesão, tente novamente.',
        });
      }
    );
  }

  termoAceito() {
    this.loading = true;
    this.termoAdesaoService.adesaoTermo(true).subscribe(
      res => {
        Swal.fire({
          icon: 'success',
          text: "Usuário aderiu ao domicilio com sucesso!"
        }).then(() => {
          this.backdrop = false;
          this.verificarAtualizacaoContato();
        });  
      },error => {
        this.loading = false;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Houve um erro ao tentar enviar a solicitação, tente novamente.',
        });
      }
    );
  }

  termoNaoAceito() {
    this.loading = true;
    this.termoAdesaoService.adesaoTermo(false).subscribe(
      res => {
        this.loading = false;
        Swal.fire({
        icon: 'warning',
        text: res
      })
      setTimeout(() => {
        localStorage.clear();
        window.location.href = "https://sso.acesso.gov.br/logout";
        window.location.href = "https://dfe.sefaz.to.gov.br";
      }, 3000);
      },error => {
        this.loading = false;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Houve um erro ao tentar enviar a solicitação, tente novamente.',
        });
       });
  }

  verificarAtualizacaoContato() {
    this.formContatos = this.formBuilder.group({
      email: ['', Validators.compose([Validators.email, Validators.required])],
      telefone: ['', Validators.required]
    });
    
    const codigoCadastro = this.usuarioLogado.codigoCadastro;
    this.cadastroService.verificarAtualizacaoContato(codigoCadastro).subscribe((res) => {
      if(!res.atualizado) {
        this.formContatos.patchValue({
          email: res.email,
          telefone: res.telefone ? `${res.telefone.ddd}${res.telefone.numero}` : ''
        });
        this.abrirModalContatos = true;
      }

      this.loading = false;
    }, error => {
      Swal.fire({
        icon: 'error',
        text: 'Ops! Ocorreu um erro ao carregar os dados de contato.'
      });

      this.loading = false;
    });
  }

  salvarContatos() {
    this.loading = true;
    const form = this.formContatos.getRawValue();
    const obj = {
      seqCadastro: this.usuarioLogado.codigoCadastro,
      email: form.email,
      telefone: {
        ddd: form.telefone.substring(0, 2),
        numero: form.telefone.substring(2, 11)
      }
    }

    this.cadastroService.salvarContatos(obj).subscribe((res) => {
      Swal.fire({
        icon: 'success',
        text: 'Contato atualizado com sucesso!'
      }).then(() => {
        this.abrirModalContatos = false;
        this.loading = false;
      });
    }, (error) => {
      Swal.fire({
        icon: 'error',
        text: 'Ops! Ocorreu um erro ao salvar os contatos.'
      }).then(() =>{
        setTimeout(() => {
          location.reload();
        }, 1000);
      });
    });
  }

  scrollEnd(evt: any) {
    if(evt.target.offsetHeight + evt.target.scrollTop >= evt.target.scrollHeight - 50) {
      this.atBottom = true;
    } else {
      this.atBottom = false;
    }
  }

  abrirModalNfae() {
    this.nfaService.abrirFecharNfa(); 
  }

  abrirRefisIpva(){
    const externalUrl = 'https://ipva.sefaz.to.gov.br/como_pagar_ipva.php';
    window.open(externalUrl, '_blank');
  }

}
